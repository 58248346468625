@media (max-width: 800px)  {
    .H_leftPanelKontakt {
        float: none;
        width: 100%;
        max-width: 100%;
    
    }
      
    .H_rightPanelKontakt {
        float: none;
        width: 100%;
        max-width: 100%;
    }

    .kirp {
        width: 25%; 
      }
    
}

@media (min-width: 800px)  {
    .H_leftPanelKontakt {
        float: left;
        height: 360px;
        width: 50%;
        max-width: 100%;
    }
    
    .H_rightPanelKontakt {
        float: right;
        height: 360px;
        width: 50%;
        max-width: 100%;

    }
    
    .kirp {
        width: 35%; 
      }
    
}


.H_MainPanel {
  text-align: justify;
  margin: 5% 5% 0% 5%;
  max-width: 100%;
}

.H_kontaktPanel {
    max-width: 100%;
    text-align: center;
    width: 100%;
    letter-spacing: 1px;
    font-size: large;
}

.H_katarzynaPanel {
    max-width: 100%;
    margin-left: 0;
    margin-top: 5%;
    margin-bottom: 6%;
    margin-right: 5%;
    text-align: left;
    width: 100%;
    letter-spacing: 1px;
    font-size: large;
}


.H_tytul {
   max-width: 100%; 
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: bold;
   font-size: x-large; 
}  


.H_tytul-opis {
    max-width: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-style: normal;
    font-size: large;
    margin-top: 3%; 
 }

 

