
.OP_MainPanel {
  text-align: justify;
  margin: 5% 5% 2% 5%;
  max-width: 100%;
  color: black;
  top: 8px;
  left: 16px;
}

.OP_tytul {
   
   text-align: center;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: bold;
   font-size: normal; 
}  

.OP_tytul_left {
    
   text-align: left;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: bold;
   font-size: normal;
   margin-top: 3%; 
}  


.OP_tytul-opis {
    
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-style: normal;
    font-size: normal;
    margin-top: 3%; 
 }

 .OP_tytul-opis_left {
    max-width: 100%;
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-style: normal;
    font-size: normal;
    margin-top: 3%; 
 }

 

 .OP_tytul-opis-center {
    max-width: 100%;
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-style: normal;
    font-size: normal;
    margin-top: 3%; 
 }

 .OP_opis1 {
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.5em;
    font-style: normal;
    font-size: normal;
    margin: 2% 0% 2% 0%;
    max-width: 100%;  
 }

 ul.checkmark {
    list-style-type: none;
    list-style-image: url(data:image/gif;base64,R0lGODlhEQASAIMAAAAAAAAAMwAAZgBVmQBVzDMAAJkrAJnV/8yAM8z///+qZv/Vmf//zP///wAAAAAAACwAAAAAEQASAEAIcwAbCBxIsKBBgwAENFjIsAEAAAEaSJxIsaLFixYZAADQoKPHjyBDihxJEqQCAAAaqFypsgCAAQYAHGhAs6bNmzhz6tzJs6dPnQoAAGhAtKhRogwAAGjAtGlTBAASNAAAIEGDq1izLgDAlUCDr2DDih1LNiAAOw==);
    list-style-position:inside;
    padding-left: 5%;
    max-width: 100%;
}



