.PO_MainPanel {
  text-align: justify;
  margin: 5% 5% 2% 5%;
  max-width: 100%;
}

.PO_tytul {
   max-width: 100%; 
   text-align: center;
   text-decoration: underline;
   text-transform: uppercase;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: normal;
   font-size: 1 em; 
}  

.PO_opis1 {
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.5em;
    font-style: normal;
    font-size: 1 em;
    margin: 2% 0% 2% 0%;
    max-width: 100%;  
 }

 