@media (max-width: 800px)  {
    .KO_leftPanelKontakt {
        float: none;
        width: 100%;
        max-width: 100%;
    
    }
      
    .KO_rightPanelKontakt {
        float: none;
        width: 100%;
        max-width: 100%;
    }
}

@media (min-width: 800px)  {
    .KO_leftPanelKontakt {
        float: left;
        height: 410px;
        width: 35%;
        max-width: 100%;
    }
    
    .KO_rightPanelKontakt {
        float: right;
        height: 410px;
        width: 65%;
        max-width: 100%;

    }    
}


.KO_MainPanel {
  text-align: justify;
  margin: 5% 5% 2% 5%;
  max-width: 100%;
}

.KO_kontaktPanel {
    max-width: 100%;
    text-align: center;
    width: 100%;
    letter-spacing: 1px;
    font-size: normal;
}

.KO_katarzynaPanel {
    max-width: 100%;
    margin-left: 0;
    margin-top: 5%;
    margin-bottom: 6%;
    margin-right: 5%;
    text-align: left;
    width: 100%;
    letter-spacing: 1px;
    font-size: normal;
}

.KO_tytul1 {
    max-width: 100%; 
    text-align: center;
    text-decoration: underline;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    line-height: 1.8;
    font-weight: normal;
    font-size: 1.1em; 
    margin-top: 50%;
 }

.KO_tytul {
   max-width: 100%; 
   text-align: center;
   text-decoration: underline;
   text-transform: uppercase;
   letter-spacing: 0.05em;
   line-height: 1.8;
   font-weight: normal;
   font-size: 1.1em; 
    
}  

.KO_link {
    max-width: 100%; 
    text-align: center;
    letter-spacing: 0.05em;
    line-height: 1.8;
    font-weight: normal;
    font-size: 1.1em;
    margin-top: 5%;
    margin-bottom: 5% 
}

.KO_tytul-opis {
    max-width: 100%;
    text-align: justify;
    letter-spacing: 0.05em;
    line-height: 1.5;
    font-style: normal;
    font-size: normal;
    margin-top: 3%; 
 }

 .wejscie {
    width: 90%; 
    max-width: 280px;
  }

