@media (max-width: 800px)  {
    .cookies_panel {
        max-width: 100%;
        position: fixed;
        background-color: blue;
        border-radius: 60px;
        border: 3px solid rgb(37, 56, 231); /* Green */
        bottom: 0;
        margin-left: 1%;
        text-align: center;
        padding: 5px 10px;
        width: 100%;
        letter-spacing: 1px;
        font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 0.5 em;
        color: beige
    
    }
    .button1 {
        max-width: 100%;
        background-color: white;
        color: black;
        border: 1px solid #4CAF50; /* Green */
        width:  15% ;
        height: auto;
        border-radius: 60px;
        font-size:0.6em;
        padding: 0.5% 1%;
        margin-left: 1%;
      }
        
      .button1:hover {
        max-width: 100%;
        background-color: #3e8e41;
        color: #fff;
      }
    
      .button1:active {
        max-width: 100%;
        background-color: #3e8e41;
        box-shadow: 0 50% #666;
        border-radius: 60px;
        transform: translateY(20%);
      }
    
      .button2 {
        max-width: 100%;
        background-color: white;
        color: black;
        border: 1px solid #4CAF50; /* Green */
        width:  40%;
        height: auto;
        border-radius: 60px;
        font-size:0.6em;
        padding: 5px 10px;
        margin-left: 5px;
      }
        
      .button2:hover {
        max-width: 100%;
        background-color: #3e8e41;
        color: #fff;
      }
    
      .button2:active {
        max-width: 100%;
        background-color: #3e8e41;
        box-shadow: 0 50% #666;
        border-radius: 60px;
        transform: translateY(20%);
      }

}

@media (min-width: 800px)  {
    .cookies_panel {
        max-width: 100%;
        position: fixed;
        background-color: blue;
        border-radius: 60px;
        border: 3px solid  rgb(37, 56, 231); /* Green */
        bottom: 0;
        margin-left: 1%;
        text-align: center;
        padding: 5px 10px;
        width: 50%;
        letter-spacing: 1px;
        font-family: "Quicksand", "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
        font-size: 1 em;
        color: beige
    }
    .button1 {
        max-width: 100%;
        background-color: white;
        color: black;
        border: 1px solid #4CAF50; /* Green */
        width:  20% ;
        height: auto;
        border-radius: 60px;
        font-size:1em;
        padding: 5px 10px;
        margin-left: 5px;
      }
        
      .button1:hover {
        max-width: 100%;
        background-color: #3e8e41;
        color: #fff;
      }
    
      .button1:active {
        max-width: 100%;
        background-color: #3e8e41;
        box-shadow: 0 50% #666;
        border-radius: 60px;
        transform: translateY(20%);
      }
    
      .button2 {
        max-width: 100%;
        background-color: white;
        color: black;
        border: 1px solid #4CAF50; /* Green */
        width:  40%;
        height: auto;
        border-radius: 60px;
        font-size:1em;
        padding: 5px 10px;
        margin-left: 5px;
      }
        
      .button2:hover {
        max-width: 100%;
        background-color: #3e8e41;
        color: #fff;
      }
    
      .button2:active {
        max-width: 100%;
        background-color: #3e8e41;
        box-shadow: 0 50% #666;
        border-radius: 60px;
        transform: translateY(20%);
      }

}


