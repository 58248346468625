body {
    line-height: 1;
    background-color: white;
}


#myLogo { 
    /*background: url('./images/icon.png?size=240'); 
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain; */
    background-color: rgb(34, 20, 78);
    /*padding: 1%; */
    color: blanchedalmond;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.3em;
    top: 0;
    position: fixed;
    height: 5%;
    width: 100%;
    z-index: 100;
    align-items: center;
    text-align: center;
    /*display: flex; */
     /*flex-wrap: wrap;  /* optional. only if you want the items to wrap */
  }

 #myMask {
  height: 15%;
  width: 100%;
  top: 0;

 } 

 #myOpis { 
  /*background: url('./images/bam.jpg?size=1200'); 
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; */
  /*width: auto;
  height: auto;
  position: flexible;
   */ 
  color: black;
  font: 1 em sans-serif;
  font-family: sans-serif;
}

.separ {
  margin-bottom: 10%;
}


.headerOpis {
    /*border: 1px solid red;*/
    clear: left;
    
} 

#before_footer {
  margin-bottom: 10%;
 }

@media (max-width: 1200px)  {
  .kancelariaHeader {
    font-size: 2vw;
  }
}

@media (min-width: 1200px)  {
  .kancelariaHeader {
    font-size: 1vw;
  }
}
 
